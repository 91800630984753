export function initEmblaCarousel() {
  if (!window.EmblaCarousel) {
    console.error('EmblaCarousel is not available')
    return
  }

  const addTogglePrevNextBtnsActive = (emblaApi, prevBtn, nextBtn) => {
    const togglePrevNextBtnsState = () => {
      if (emblaApi.canScrollPrev()) {
        prevBtn.removeAttribute('disabled')
      } else {
        prevBtn.setAttribute('disabled', 'disabled')
      }

      if (emblaApi.canScrollNext()) {
        nextBtn.removeAttribute('disabled')
      } else {
        nextBtn.setAttribute('disabled', 'disabled')
      }
    }

    emblaApi
      .on('select', togglePrevNextBtnsState)
      .on('init', togglePrevNextBtnsState)
      .on('reInit', togglePrevNextBtnsState)

    return () => {
      prevBtn.removeAttribute('disabled')
      nextBtn.removeAttribute('disabled')
    }
  }

  const addPrevNextBtnsClickHandlers = (emblaApi, prevBtn, nextBtn) => {
    const scrollPrev = () => {
      emblaApi.scrollPrev()
    }

    const scrollNext = () => {
      emblaApi.scrollNext()
    }

    prevBtn.addEventListener('click', scrollPrev, false)
    nextBtn.addEventListener('click', scrollNext, false)

    const removeTogglePrevNextBtnsActive = addTogglePrevNextBtnsActive(
      emblaApi,
      prevBtn,
      nextBtn
    )

    return () => {
      removeTogglePrevNextBtnsActive()
      prevBtn.removeEventListener('click', scrollPrev, false)
      nextBtn.removeEventListener('click', scrollNext, false)
    }
  }

  const addDotBtnsAndClickHandlers = (emblaApi, dotsNode) => {
    let dotNodes = []

    const addDotBtnsWithClickHandlers = () => {
      dotsNode.innerHTML = emblaApi
        .scrollSnapList()
        .map(() => '<button class="embla__dot" type="button"></button>')
        .join('')

      const scrollTo = (index) => {
        emblaApi.scrollTo(index)
      }

      dotNodes = Array.from(dotsNode.querySelectorAll('.embla__dot'))

      dotNodes.forEach((dotNode, index) => {
        dotNode.addEventListener('click', () => scrollTo(index), false)
      })
    }

    const toggleDotBtnsActive = () => {
      const previous = emblaApi.previousScrollSnap()
      const selected = emblaApi.selectedScrollSnap()

      dotNodes[previous].classList.remove('embla__dot--selected')
      dotNodes[selected].classList.add('embla__dot--selected')
    }

    emblaApi
      .on('init', addDotBtnsWithClickHandlers)
      .on('reInit', addDotBtnsWithClickHandlers)
      .on('init', toggleDotBtnsActive)
      .on('reInit', toggleDotBtnsActive)
      .on('select', toggleDotBtnsActive)

    return () => {
      dotsNode.innerHTML = ''
    }
  }

  const TWEEN_FACTOR_BASE = 0.2
  let tweenFactor = 0
  let tweenNodes = []

  const numberWithinRange = (number, min, max) => Math.min(Math.max(number, min), max)

  const setTweenNodes = (emblaApi) => {
    tweenNodes = emblaApi.slideNodes().map((slideNode) => {
      return slideNode.querySelector('.embla__slide__number')
    })
  }

  const setTweenFactor = (emblaApi) => {
    tweenFactor = TWEEN_FACTOR_BASE * emblaApi.scrollSnapList().length
  }

  const tweenScale = (emblaApi, eventName) => {
    const engine = emblaApi.internalEngine()
    const scrollProgress = emblaApi.scrollProgress()
    const slidesInView = emblaApi.slidesInView()
    const isScrollEvent = eventName === 'scroll'

    emblaApi.scrollSnapList().forEach((scrollSnap, snapIndex) => {
      let diffToTarget = scrollSnap - scrollProgress
      const slidesInSnap = engine.slideRegistry[snapIndex]

      slidesInSnap.forEach((slideIndex) => {
        if (isScrollEvent && !slidesInView.includes(slideIndex)) {
          return
        }

        if (engine.options.loop) {
          engine.slideLooper.loopPoints.forEach((loopItem) => {
            const target = loopItem.target()

            if (slideIndex === loopItem.index && target !== 0) {
              const sign = Math.sign(target)

              if (sign === -1) {
                diffToTarget = scrollSnap - (1 + scrollProgress)
              }

              if (sign === 1) {
                diffToTarget = scrollSnap + (1 - scrollProgress)
              }
            }
          })
        }

        const tweenValue = 1 - Math.abs(diffToTarget * tweenFactor)
        const scale = numberWithinRange(tweenValue, 0, 1).toString()
        const tweenNode = tweenNodes[slideIndex]

        tweenNode.style.transform = `scale(${scale})`
      })
    })
  }

  const setupTweenScale = (emblaApi) => {
    setTweenNodes(emblaApi)
    setTweenFactor(emblaApi)
    tweenScale(emblaApi)

    emblaApi
      .on('reInit', setTweenNodes)
      .on('reInit', setTweenFactor)
      .on('reInit', tweenScale)
      .on('scroll', tweenScale)
      .on('slideFocus', tweenScale)

    return () => {
      tweenNodes.forEach((slide) => slide.removeAttribute('style'))
    }
  }

  const OPTIONS = { loop: true }

  const emblaNode = document.querySelector('.embla')
  const viewportNode = emblaNode.querySelector('.embla__viewport')
  const prevBtn = emblaNode.querySelector('.embla__button--prev')
  const nextBtn = emblaNode.querySelector('.embla__button--next')
  const dotsNode = document.querySelector('.embla__dots')

  const emblaApi = window.EmblaCarousel(viewportNode, OPTIONS)
  const removeTweenScale = setupTweenScale(emblaApi)

  const removePrevNextBtnsClickHandlers = addPrevNextBtnsClickHandlers(
    emblaApi,
    prevBtn,
    nextBtn
  )
  const removeDotBtnsAndClickHandlers = addDotBtnsAndClickHandlers(
    emblaApi,
    dotsNode
  )

  emblaApi
    .on('destroy', removeTweenScale)
    .on('destroy', removePrevNextBtnsClickHandlers)
    .on('destroy', removeDotBtnsAndClickHandlers)

  initVideoControls()

  function initVideoControls() {
    const videoContainer = document.querySelector('.video-carousel');

    if (!videoContainer) {
      return;
    }

    const videos = videoContainer.querySelectorAll('.video-container')

    videos.forEach((videoContainer) => {
      const video = videoContainer.querySelector('.video-player');
      const videoCaption = videoContainer.querySelector('.video-caption');

      video.addEventListener('play', () => {
        videoCaption.classList.add('video-caption--hidden');
      });

      video.addEventListener('pause', () => {
        videoCaption.classList.remove('video-caption--hidden');
      });

      video.addEventListener('ended', () => {
        videoCaption.classList.remove('video-caption--hidden');
      });
    });
  }
}
