import { getSocket } from './getSocket'
import { getSocketId } from './getSocketId';

export async function useSocket(params) {
  const {
    messages,
    onChangeConnectState,
    callBack,
  } = params;

  const chainId = await getSocketId();
  const socket = getSocket(chainId);

  const onConnect = () => {
    socket.on('connect', onChangeConnectState(true))
    socket.on('disconnect', onChangeConnectState(false))

    messages.forEach((message) => {
      socket.on(message, (data) => callBack(message, data))
    })
  }

  const onDisconnect = () => {
    socket.off('connect', onChangeConnectState(true))
    socket.off('disconnect', onChangeConnectState(false))
    messages.forEach((message) => {
      socket.off(message, (data) => callBack(message, data))
    })
  }

  if (socket.id) {
    onConnect()
  }

  window.addEventListener('beforeunload', onBeforeunload);

  function onBeforeunload() {
    onDisconnect();
    window.removeEventListener('beforeunload', onBeforeunload);
  }
}
