const htmlEl = {
  accordionsBtn: document.querySelectorAll(".accordion__head"),
};

//Функционал раскрывающихся FAQ(начало)
const openAccordion = (accordion) => {
  const content = accordion.nextElementSibling;
  const accordionParent = accordion.closest(".accordion");
  accordionParent.classList.add("accordion--active");
  content.style.maxHeight = content.scrollHeight + "px";
};

const closeAccordion = (accordion) => {
  const content = accordion.nextElementSibling;
  const accordionParent = accordion.closest(".accordion");
  accordionParent.classList.remove("accordion--active");
  content.style.maxHeight = null;
};

htmlEl.accordionsBtn.forEach((accordion) => {
  const content = accordion.nextElementSibling;

  accordion.onclick = (e) => {
    e.preventDefault();
    if (content.style.maxHeight) {
      closeAccordion(accordion);
    } else {
      htmlEl.accordionsBtn.forEach((accordion) => closeAccordion(accordion));
      openAccordion(accordion);
    }
  };
});
//Функционал раскрывающихся FAQ(конец)

const dropdownBtns = document.querySelectorAll(".dropdownUI__select");

//Открытие-закрытие dropdown(начало)
dropdownBtns.forEach((btn) => {
  btn.addEventListener("click", () => {
    btn.classList.toggle("dropdownUI__select--active");
  });
});

window.addEventListener("click", function (e) {
  if (
    document.querySelector(".dropdownUI__select--active") &&
    !document.querySelector(".dropdownUI__select--active").contains(e.target)
  ) {
    dropdownBtns.forEach((btn) =>
      btn.classList.remove("dropdownUI__select--active"),
    );
  }
});
//Открытие-закрытие dropdown(конец)

(() => {
  const yearNode = document.querySelector(".footer__year")

  if (yearNode) {
    yearNode.textContent = new Date().getFullYear().toString()
  }
})()
