import numbro from 'numbro';
import { formatUnits } from 'viem';
import { useSocket } from './init-socket';

const WEI_DECIMALS = 18;
const MAX_DECIMALS = 4;

function fromWei(balance, unitName = WEI_DECIMALS) {
  return formatUnits(BigInt(balance), unitName)
}

function formatPrice(_value, decimals = 0, isFiat = false) {
  const value = Number(_value)
  const minValue = Math.pow(10, -decimals)

  if (value < minValue && value > 0) {
    if (isFiat) {
      return `< ${numbro(minValue).formatCurrency({ mantissa: decimals })}`
    }
    return `< ${numbro(minValue).format({ mantissa: decimals })}`
  }

  if (isFiat) {
    return numbro(value).formatCurrency({
      trimMantissa: true,
      mantissa: decimals,
    })
  }

  return numbro(value).format({
    mantissa: 4,
    roundingFunction: (num) => {
      const [_decimals, _substringDecimals] = String(num).split('.')
      const padEndCount = _substringDecimals?.length ?? 0
      const roundingMidpoint = padEndCount ? 5 * 10 ** (padEndCount - 1) : 5

      return Number(_substringDecimals?.padEnd(padEndCount, '0')) >= roundingMidpoint
        ? Number(_decimals) + 1
        : Number(_decimals)
    },
  })
}

const formatValue = (value, decimals) => {
  return formatPrice(fromWei(value, decimals), MAX_DECIMALS)
}

export const initOktokenWidget = () => {
  const okTokenRoots = document.querySelectorAll('.oktoken-stats');

  okTokenRoots.forEach((okTokenRoot) => {
    const statsExchangeRateValue = okTokenRoot.querySelector('.stats-exchange-rate');
    const statsUsdtAmountValue = okTokenRoot.querySelector('.stats-usdt-amount');
    const statsOktAmountValue = okTokenRoot.querySelector('.stats-okt-amount');

    statsExchangeRateValue.textContent = '**********';
    statsUsdtAmountValue.textContent = '**********';
    statsOktAmountValue.textContent = '**********';
  });

  getStats();

  useSocket({
    messages: ['stats'],
    callBack: (message, data) => {
      if (message === 'stats') {
        okTokenRoots.forEach((okTokenRoot) => {
          updateStats(okTokenRoot, data);
        });
      }
    },
  })

  async function getStats() {
    const response = await fetch('https://app.okt.global/api/prices/stats');
    const data = await response.json();

    okTokenRoots.forEach((okTokenRoot) => {
      updateStats(okTokenRoot, data);
    });
  }

  function updateStats(rootElement, data) {
    const statsExchangeRateValue = rootElement.querySelector('.stats-exchange-rate');
    const statsUsdtAmountValue = rootElement.querySelector('.stats-usdt-amount');
    const statsOktAmountValue = rootElement.querySelector('.stats-okt-amount');

    statsExchangeRateValue.textContent = formatValue(data.rate, 6);
    statsUsdtAmountValue.textContent = formatValue(data.totalAsset, 6);
    statsOktAmountValue.textContent = formatValue(data.totalSupply, 18);
  }
};
